import styled from "styled-components";
import { useEffect, useState } from "react";

interface IAttributes {
  backgrounds: [{ name: string }];
  scribbles: [{ name: string }];
  heads: [{ name: string }];
  tattoos: [{ name: string }];
  skins: [{ name: string }];
  tops: [{ name: string }];
  baseUrl: [{ name: string }];
  extention: string;
  pathTraits: {
    background: string;
    scribble: string;
    tattoo: string;
    skin: string;
    head: string;
    top: string;
  };
}

export const Builder = () => {
  const [background, setBackground] = useState("Gradient-5");
  const [scribble, setScribble] = useState("Scribble - Old Wall");
  const [skin, setSkin] = useState("Gradient");
  const [tattoo, setTattoo] = useState("Tattoo - Car Parts");
  const [top, setTop] = useState("Vest - Purple");
  const [head, setHead] = useState("Parking Meter - Classic");
  const [attributes, setAttributes] = useState<IAttributes>();

  useEffect(() => {
    getData().then((e) => setAttributes(e));
  }, []);

  if (!attributes) return <div>loading</div>;

  const {
    backgrounds,
    scribbles,
    heads,
    tattoos,
    skins,
    tops,
    baseUrl,
    extention,
    pathTraits: {
      background: pathBackground,
      scribble: pathScribble,
      tattoo: pathTattoo,
      skin: pathSkin,
      head: pathHead,
      top: pathTop,
    },
  } = attributes;

  scribbles.sort((a, b) => a.name.localeCompare(b.name));
  heads.sort((a, b) => a.name.localeCompare(b.name));
  tops.sort((a, b) => a.name.localeCompare(b.name));
  skins.sort((a, b) => a.name.localeCompare(b.name));
  backgrounds.sort((a, b) => a.name.localeCompare(b.name));
  tattoos.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Container>
      <div className="layout">
        <img
          src={`${baseUrl}${pathBackground}/${background}.${extention}`}
          alt="background"
        />
        <img
          src={`${baseUrl}${pathScribble}/${scribble}.${extention}`}
          alt="background"
        />
        <img src={`${baseUrl}${pathSkin}/${skin}.${extention}`} alt="skin" />
        <img
          src={`${baseUrl}${pathTattoo}/${tattoo}.${extention}`}
          alt="tattoo"
        />
        <img src={`${baseUrl}${pathTop}/${top}.${extention}`} alt="top" />
        <img src={`${baseUrl}${pathHead}/${head}.${extention}`} alt="head" />
      </div>
      <div>
        <h2>Options</h2>
        <div className="selector">
          <div className="label">Background: </div>
          <select
            name="Background"
            id="Backgroundt"
            onChange={(event) => setBackground(event.target.value)}
          >
            {backgrounds.map((e) => {
              return (
                <option value={e.name.replaceAll(" ", "%20")}>{e.name}</option>
              );
            })}
          </select>
        </div>
        <div className="selector">
          <div className="label">Scribble</div>
          <select
            name="scribble"
            id="scribble"
            onChange={(event) => setScribble(event.target.value)}
          >
            {scribbles.map((e) => {
              return (
                <option value={e.name.replaceAll(" ", "%20")}>{e.name}</option>
              );
            })}
          </select>
        </div>
        <div className="selector">
          <div className="label">Skin:</div>
          <select
            name="skin"
            id="skin"
            onChange={(event) => setSkin(event.target.value)}
          >
            {skins.map((e) => {
              return (
                <option value={e.name.replaceAll(" ", "%20")}>{e.name}</option>
              );
            })}
          </select>
        </div>
        <div className="selector">
          <div className="label">Tattoo:</div>
          <select
            name="tattoo"
            id="tattoo"
            onChange={(event) => setTattoo(event.target.value)}
          >
            {tattoos.map((e) => {
              return (
                <option value={e.name.replaceAll(" ", "%20")}>{e.name}</option>
              );
            })}
          </select>
        </div>
        <div className="selector">
          <div className="label">Tops:</div>
          <select
            name="tops"
            id="top"
            onChange={(event) => setTop(event.target.value)}
          >
            {tops.map((e) => {
              return (
                <option value={e.name.replaceAll(" ", "%20")}>{e.name}</option>
              );
            })}
          </select>
        </div>
        <div className="selector">
          <div className="label">Head:</div>
          <select
            name="head"
            id="head"
            onChange={(event) => setHead(event.target.value)}
          >
            {heads.map((e) => {
              return (
                <option value={e.name.replaceAll(" ", "%20")}>{e.name}</option>
              );
            })}
          </select>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  padding: 10px;
  display: flex;
  max-width: 50vw;
  justify-content: center;
  flex-direction: column;
  border: 1px solid black;
  margin: 5px;
  color: black;
  @media (max-width: 415px) {
    width: 320px;
  }
  h2 {
    padding-left: 10px;
  }
  .layout {
    position: relative;
    width: 400px;
    height: 400px;
    @media (max-width: 415px) {
      width: 320px;
      height: 320px;
    }

    img {
      position: absolute;
      width: 400px;
      height: 400px;
      @media (max-width: 415px) {
        width: 320px;
        height: 320px;
      }
    }
  }
  .selector {
    padding-left: 10px;
    display: flex;
    .label {
      width: 30%;
    }
    select {
      width: 50%;
    }
  }
`;

const getData = () => {
  return fetch("data/attributes.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then((e) => {
      return e;
    });
};
