import { atom } from "recoil";

export const displayFaq = atom({
  key: "displayFaq",
  default: true,
});

export const displayRoadmap = atom({
  key: "displayRoadmap",
  default: true,
});

export const displayBuilder = atom({
  key: "displayBuilder",
  default: true,
});

export const displayAbout = atom({
  key: "displayAbout",
  default: true,
});

export const displayTrashFolder = atom({
  key: "displayTrashFolder",
  default: true,
});

export const displayVideoFolder = atom({
  key: "displayVideoFolder",
  default: true,
});

export const displayVideoPlayer = atom({
  key: "displayVideoPlayer",
  default: true,
});

export const displayToolsFolder = atom({
  key: "displayTools",
  default: true,
});

export const displayVanityGeneratorTool = atom({
  key: "displayVanityGeneratorTool",
  default: true,
});