import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import moment from "moment";
import { useRecoilState, useRecoilValue } from "recoil";
import * as Atom from "../atom";
import * as display from "../atom/diplayWindows";


interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = () => {
  const [menuWindows, setMenuWindows] = useState<Boolean>(false);
  const [builder, setPreview3d] = useRecoilState(Atom.builderAtom);
  const [about, setAbout] = useRecoilState(Atom.aboutAtom);
  const [roadmap, setRoadmap] = useRecoilState(Atom.roadmapAtom);
  const [displayBuilder, setDisplayBuilder] = useRecoilState(
    display.displayVanityGeneratorTool
  );
  const [vanityGenerator ] = useRecoilState(Atom.vanityGeneratorAtom);
  const [displayVanityGenerator, setDisplayVanityGenerator] = useRecoilState(
    display.displayBuilder
  );


  const [displayFaq, setDisplayFaq] = useRecoilState(display.displayFaq);
  const [displayAbout, setDisplayAbout] = useRecoilState(display.displayAbout);
  const [displayRoadmap, setDisplayRoadmap] = useRecoilState(
    display.displayRoadmap
  );

  const [videoFolder, setVideoFolder] = useRecoilState(Atom.videoFolderAtom);
  const [displayVideoFolder, setDisplayVideoFolder] = useRecoilState(display.displayVideoFolder);
  const videoPlayer = useRecoilValue(Atom.videoPlayer);
  const [displayVideoPlayer, setDisplayVideoPlayer] = useRecoilState(
    display.displayVideoPlayer
  );

  const [trashFolder, setTrashFolder] = useRecoilState(Atom.trashFolderAtom);
  const [displayTrashFolder, setDisplayTrashFolder] = useRecoilState(display.displayTrashFolder);

  const [toolsFolder, setToolsFolder] = useRecoilState(Atom.toolsFolderAtom);
  const [displayToolsFolder, setDisplayToolsFolder] = useRecoilState(display.displayToolsFolder);
  const [faq, setFaqWindow] = useRecoilState(Atom.faqAtom);
  const [date, setDate] = useState(new Date());

  const ref = useRef<any>();
  const isPhone = window.innerWidth < 415;

  useEffect(() => {
    (async () => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target) && menuWindows) {
          setMenuWindows(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    })();
  }, [menuWindows, ref]);

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <FooterContainer>
      <Start onClick={() => setMenuWindows(!menuWindows)}>
        <ImgStart src="icons/sunlogo-64px.png" />
        Start
      </Start>
      {builder ? (
        <Start onClick={() => setDisplayBuilder(!displayBuilder)}>
          <ImgStart src="icons/rebel.png" />
          RebelBuilder.exe
        </Start>
      ) : (
        ""
      )}
      {roadmap ? (
        <Start onClick={() => setDisplayRoadmap(!displayRoadmap)}>
          <ImgStart src="icons/roadmap.png" />
          SunRebels
        </Start>
      ) : (
        ""
      )}
      {faq ? (
        <Start onClick={() => setDisplayFaq(!displayFaq)}>
          <ImgStart src="icons/faq.png" />
          XRPL Guide.docx
        </Start>
      ) : (
        ""
      )}
      {about ? (
        <Start onClick={() => setDisplayAbout(!displayAbout)}>
          <ImgStart src="icons/about.png" />
          SORRISO
        </Start>
      ) : (
        ""
      )}
      {videoFolder ? (
        <Start onClick={() => setDisplayVideoFolder(!displayVideoFolder)}>
          <ImgStart src="icons/folder.png" />
          Video
        </Start>
      ) : (
        ""
      )}
      {videoPlayer ? (
        <Start onClick={() => setDisplayVideoPlayer(!displayVideoPlayer)}>
          <ImgStart src="icons/videoPlayer.png" />
          Video Player
        </Start>
      ) : (
        ""
      )}
      {trashFolder ? (
        <Start onClick={() => setDisplayTrashFolder(!displayTrashFolder)}>
          <ImgStart src="icons/trash.png" />
          Trash
        </Start>
      ) : (
        ""
      )}
      {toolsFolder ? (
        <Start onClick={() => setDisplayToolsFolder(!displayToolsFolder)}>
          <ImgStart src="icons/folder.png" />
          Tools
        </Start>
      ) : (
        ""
      )}
      {vanityGenerator ? (
        <Start onClick={() => setDisplayVanityGenerator(!displayVanityGenerator)}>
          <ImgStart src="/icons/windows98-icons/png/key_padlock_help.png" />
          Vanity Generator
        </Start>
      ) : (
        ""
      )}
      <StartMenu ref={ref} isActive={menuWindows}>
        <MenuItem href="#Faq" onClick={() => !isPhone && setFaqWindow(1)}>
          XRPL Guide.docx
        </MenuItem>
        <MenuItem href="#Builder" onClick={() => !isPhone && setPreview3d(1)}>
          RebelBuilder.exe
        </MenuItem>
        <MenuItem href="#Roadmap" onClick={() => !isPhone && setRoadmap(1)}>
          SunRebels
        </MenuItem>
        <MenuItem href="#About" onClick={() => !isPhone && setAbout(1)}>
          SORRISO
        </MenuItem>
        <MenuItem href="#Video" onClick={() => !isPhone && setVideoFolder(1)}>
          Video
        </MenuItem>
        <MenuItem href="#Trash" onClick={() => !isPhone && setTrashFolder(1)}>
          Trash
        </MenuItem>
        <MenuItem href="#Tools" onClick={() => !isPhone && setToolsFolder(1)}>
          Tools
        </MenuItem>
      </StartMenu>

      <Social>
        <Separator />
        <SocialLink href="https://discord.gg/sunrebels" target="_bank">
          <Img src="icons/discordPhone.png" />
        </SocialLink>
        <Separator />
        <SocialLink href="https://twitter.com/SunRebels" target="_bank">
          <Img src="icons/twitterPhone.png" />
        </SocialLink>
        {!isPhone && <Separator />}
      </Social>
      <DateWindows>
        {isPhone ? (
          moment().format("h:mm a")
        ) : (
          <DateDiv>
            <img
              src="https://images-ext-1.discordapp.net/external/3YjBagcEkLXbTBwhJNcR3vWdkc0WuyhTtItme2zWqrQ/%3Fsize%3D96%26quality%3Dlossless/https/cdn.discordapp.com/emojis/811261551981232218.gif"
              alt="pepe"
              style={{ height: "100%", marginRight: "10px" }}
            /> 
            {moment(date).format("h:mm a")}
          </DateDiv>
        )}
      </DateWindows>
    </FooterContainer>
  );
};

export default Footer;

// const WalletButton = styled(WalletDialogButton)`
//   margin-left: 10px;
//   background-size: 17px 15px;
//   text-align: right;
//   font-weight: bolder;
//   font-size: 16px;
//   width: fit-content;
//   display: flex;
//   cursor: default;
//   height: 80%;
//   background: #c3c6cd;
//   border-top: 2px solid #deebf3;
//   border-left: 2px solid #deebf3;
//   border-right: 2px solid black;
//   border-bottom: 2px solid black;
//   user-select: none;
//   align-items: center;
//   justify-content: center;
//   background-color: transparent;
//   cursor: pointer;
//   position: relative;
//   margin-left: 10px;
//   padding-left: 5px;
//   &:active {
//     border-top: 2px solid black;
//     border-left: 2px solid black;
//     border-right: 2px solid;
//     border-bottom: 2px solid white;
//   }
//   img {
//     margin-left: 10px;
//   }

//   @media mobile and (max-width: 415px) {
//     width: 30%;
//   }
// `;

const Social = styled.div`
  display: none;
  height: 100%;
  width: 25%;
  justify-content: space-between;
  @media mobile and (max-width: 415px) {
    display: flex;
    width: 30%;
  }
`;
const SocialLink = styled.a`
  width: 49%;
  margin: auto;
  display: flex;
  justify-content: center;
`;

const FooterContainer = styled.div`
  font-size: 8px;
  height: 38px;
  background-color: #c3c6cd;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-top: 3px solid #deebf3;
  display: flex;
  align-items: center;
  z-index: 11;
  @media (max-width: 415px) {
    display: none;
  }
`;

const Start = styled.button`
  background-size: 17px 15px;
  text-align: right;
  font-weight: bolder;
  font-size: 16px;
  width: fit-content;
  display: flex;
  cursor: default;
  height: 80%;
  background: #c3c6cd;
  border-top: 2px solid #deebf3;
  border-left: 2px solid #deebf3;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  user-select: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
  padding-left: 5px;
  &:active {
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid;
    border-bottom: 2px solid white;
  }
  img {
    margin-left: 10px;
  }

  @media mobile and (max-width: 415px) {
    width: 30%;
  }
`;

const StartMenu = styled.div<{ isActive: Boolean }>`
  background: #c3c6cd;
  border-top: 2px solid #deebf3;
  border-left: 2px solid #deebf3;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  width: 20%;
  display: none;
  position: absolute;
  bottom: 38px;
  bottom: 39px;
  left: 0px;
  display: ${(props) => (props.isActive ? "block" : "none")};
  z-index: 1;
`;

const MenuItem = styled.a`
  text-decoration: none;
  color: black;
  height: 38px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-bottom: 1px solid black;
  cursor: pointer;
  &:hover {
    background: rgb(0, 32, 125);
    color: white;
  }
`;

const DateWindows = styled.div`
  width: fit-content;
  font-weight: bolder;
  font-size: 16px;
  height: 72%;
  color: black;
  right: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  @media mobile and (max-width: 415px) {
    display: none;
  }
`;

const Img = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

const ImgStart = styled(Img)`
  margin-left: 0 !important;
`;

const Separator = styled.div`
  width: 2px;
  height: 100%;
  background-color: black;
`;

const DateDiv = styled.div`
  padding: 0 5px;
  display: flex;
  height: 100%;
  align-items: center;
  background: #c3c6cd;
  border-top: 2px solid #85888f;
  border-left: 2px solid #85888f;
  border-right: 2px solid #deebf3;
  border-bottom: 2px solid #deebf3;
  margin: 3px;
`;
