import styled from "styled-components";
import Footer from "./Footer";

interface ILayout {}

export const Layout: React.FC<ILayout> = (props) => {
  return (
    <Section>
      {props.children}
      <Footer />
    </Section>
  );
};

const Section = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  font-family: "Microsoft Sans Serif";
  background-image: url("/img/sunrebels-bg1.png");
  background-size: cover;
  overflow: scroll
  @media (max-width: 415px) {
    overflow: scroll;
  }
  @media (max-device-width: 1415px) {
    @media (orientation: landscape) {
      overflow: scroll;
    }
  }
`;
