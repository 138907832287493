import styled from "styled-components";

export const TrashFolder = () => {
  return (
    <Container>
      <Content>
        Send your trash nfts to {" "}<span style={{fontWeight: "bolder", paddingLeft: "5px"}}>rTraShFezP1gg3KwwcD4Jgqq8DJqscYWL</span>
      </Content>
    </Container>
  );
};

const Content = styled.div`
  padding: 20px 0;
  color: black;
  display: flex;
  padding: 10px;
  line-height: 20px;
  width: 100%;
  height: 90%;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 415px) {
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
`;

const Container = styled.section`
  max-width: 90vw;
  min-width: 600px;
  width: fit-content;
  margin: 5px;
  border: 1px solid black;
  @media (max-width: 415px) {
    overflow: scroll;
    flex-direction: column;
    align-items: center;
  }
`;
