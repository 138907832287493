import styled from "styled-components";
import { Collapse } from "../_main/Collapse";
import { artistDatas } from "../../constants";

export const About = () => {
  return (
    <Container>
      <Content>
        <Item>
          <Infos>
            <h3>Greetings! I am SORRISO.</h3>
            <p>I'm a 22-year-old artist from the vibrant heart of Brazil. Since 2017, I've been crafting my own artistic masterpieces, but my fascination with art has been a lifelong affair. My journey began as a graphic designer, a role that honed my skills but eventually left me yearning for a canvas where I could fully unleash my creativity. So, I took the leap and began creating my own art, a decision that has been nothing short of liberating. 🔴</p>

            <p>In October 2020, I ventured into the dynamic world of NFTs, thanks to a few friends who introduced me to this revolutionary space. It was an instant connection, a love story written in the stars. My project, Sun Rebels, was born out of pure passion, but as I delved deeper, I discovered its true potential. It evolved into a canvas where I could weave stories, build lore, and continuously refine my art, all while incorporating my love for fashion and the grunge style that has always captivated me. Sun Rebels is the perfect blend of my passions - art, fashion, and NFTs, a project that truly embodies my spirit. ❤️</p>


            <h3 style={{textAlign: "center", width: "100%"}}>A Quick Interview: Getting to know Sorriso 👨‍🎨</h3>
          </Infos>
          <Collapse datas={artistDatas} />
        </Item>
      </Content>
    </Container>
  );
};

const Item = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
  flex-direction: column;
  max-width: 800px;
  margin: 20px 20px;
`;

const Infos = styled.div`
  display: flex;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 415px) {
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
`;

const Container = styled.section`
  min-height: 60vh;
  max-height: 80vh;
  overflow: scroll;
  border: 1px solid black;
  @media (max-width: 415px) {
    overflow: scroll;
    flex-direction: column;
    align-items: center;
  }
`;
