import "./App.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { RecoilRoot } from "recoil";

import Home from "./components/home";

import { Layout } from "./components/layouts/Layout";

const App = () => {
  return (
    <RecoilRoot>
      <Router>
          <Switch>
              <Layout>
                <Home />
              </Layout>
          </Switch>
      </Router>
    </RecoilRoot>
  );
};

export default App;
