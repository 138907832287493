import { useRecoilState } from "recoil";
import styled from "styled-components";
import * as Atom from "../../atom";

export const VideoFolder = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [videoPlayerWindow, setVideoPlayerWindow] = useRecoilState(
    Atom.videoPlayer
  );

  return (
    <Container>
      <Content>
        <ContainerIcon>
          <IconWindows
            className="handle"
            onClick={() => setVideoPlayerWindow(1)}
          >
            <Img src="icons/videoPlayer.png" />
            <Label>Video_01.mp4</Label>
          </IconWindows>
        </ContainerIcon>
      </Content>
    </Container>
  );
};

const ContainerIcon = styled.div`
  display: flex;
  margin-top: 10px;
`;

const IconWindows = styled.div`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  width: 42px;
  height: 42px;
  margin: 0px 30px 30px 30px;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  color: white;
`;

const Label = styled.div`
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  color: black;
`;

const Img = styled.img`
  width: 80%;
  height: 80%;
`;

const Content = styled.div`
  display: flex;
  width: 360px;
  height: 200px;
  margin: 5px;
  background-color: white;
  flex-wrap: wrap;
  @media (max-width: 415px) {
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
`;

const Container = styled.section`
  max-width: 50vh;
  border: 1px solid black;
  @media (max-width: 415px) {
    overflow: scroll;
    flex-direction: column;
    align-items: center;
  }
`;
