import styled from "styled-components";

export const VanityGenerator = () => {
  return (
    <Container>
      <div className="layout">
        <img
          src="/icons/sunlogo-64px.png"
          alt="background"
        />
        <p className="title" >Sun Rebels Vanity Address Generator</p>
        
        </div>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
  padding: 10px;
  display: flex;
  max-width: 50vw;
  justify-content: center;
  flex-direction: column;
  border: 1px solid black;
  margin: 5px;
  color: black;
  @media (max-width: 415px) {
    width: 320px;
  }
  h2 {
    padding-left: 10px;
  }
  .layout {
    position: relative;
    margin: auto;
    width: 400px;
    @media (max-width: 415px) {
      width: 320px;
      height: 320px;
    }

    img {
      margin: auto;
      display: block;
      width: 64px;
      height: 64px;
      @media (max-width: 415px) {
        width: 320px;
        height: 320px;
      }
    }
    p.title {
      font-weight: bold;
      text-align: center;
      }
    div.title{
        font-weight: bold;
        text-align: center;
    }
  }
  .selector {
    padding-left: 10px;
    display: flex;
    .label {
      width: 30%;
    }
    select {
      width: 50%;
    }
  }
`;
// 8EC1468532184561C6ABC3D307813BE6E32A40A77F3A01658514BBABB41B2CBE
// const getData = () => {
//   return fetch("data/attributes.json", {
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//   })
//     .then(function (response) {
//       return response.json();
//     })
//     .then((e) => {
//       return e;
//     });
// };
