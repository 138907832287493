import { atom } from "recoil";

export const toolsFolderAtom = atom({
  key: "toolsFolderAtom",
  default: 0,
});

export const videoFolderAtom = atom({
  key: "videoFolderAtom",
  default: 0,
});

export const trashFolderAtom = atom({
  key: "trashFolderAtom",
  default: 0,
});

