import styled from "styled-components";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { isMobile } from "react-device-detect";
import * as Atom from "../atom";
import * as Display from "../atom/diplayWindows";

import { Windows } from "../_main/Windows";
// 'Applications'
import { Builder } from "./Builder";
import { About } from "./About";
import { Faq } from "./Faq";
import { Roadmap } from "./Roadmap";

// 'Folders'
import { VideoFolder } from "./Folders/Video";
import { ToolsFolder } from "./Folders/Tools";
import { TrashFolder } from "./Folders/Trash";

// Constant variables
import { discordUrl, twitterUrl, xrpCafeUrl } from "../../constants";
import { VanityGenerator } from "../tools/VanityGenerator";

const Home = () => {
  const [builder, setBuilder] = useRecoilState(Atom.builderAtom);
  const [roadMapWindows, setRoadmap] = useRecoilState(Atom.roadmapAtom);
  const [faqWindow, setFaqWindow] = useRecoilState(Atom.faqAtom);
  const [aboutWindow, setAboutWindow] = useRecoilState(Atom.aboutAtom);
  const [videoFolderWindow, setVideoFolderWindow] = useRecoilState(Atom.videoFolderAtom);
  const [videoPlayerWindow, setVideoPlayerWindow] = useRecoilState(Atom.videoPlayer);
  const [toolsFolderWindow, setToolsFolderWindow] = useRecoilState(Atom.toolsFolderAtom);
  const [vanityToolWindow, setVanityTool] = useRecoilState(Atom.vanityGeneratorAtom);
  const [trashFolderWindow, setTrashFolderWindow] = useRecoilState(Atom.trashFolderAtom);

  const isDisplayFaq = useRecoilValue(Display.displayFaq);
  const isDisplayBuilder = useRecoilValue(Display.displayBuilder);
  const isDisplayRoadmap = useRecoilValue(Display.displayRoadmap);
  const isDisplayAbout = useRecoilValue(Display.displayAbout);
  const isDisplayVideoFolder = useRecoilValue(Display.displayVideoFolder);
  const isDisplayVideoPlayer = useRecoilValue(Display.displayVideoPlayer);
  const isDisplayToolsFolder = useRecoilValue(Display.displayToolsFolder);
  const isDisplayTrashFolder = useRecoilValue(Display.displayTrashFolder);
  const isDisplayVanityTool = useRecoilValue(Display.displayVanityGeneratorTool);

  useEffect(() => {
    if (isMobile) {
      setAboutWindow(1);
      setFaqWindow(1);
      setRoadmap(1);
      setBuilder(1);
      setVideoPlayerWindow(1);
      setTrashFolderWindow(1);
      setToolsFolderWindow(1);
      setVanityTool(1);
    }
  }, [
    setAboutWindow,
    setFaqWindow,
    setRoadmap,
    setBuilder,
    setVideoPlayerWindow,
    setTrashFolderWindow,
    setToolsFolderWindow,
    setVanityTool,
  ]);

  return (
    <HomeContainer overFlow={isMobile}>
      <ContainerIconRight isMobile={isMobile}>
        {/* Trash Icon */}
        <IconWindows
          className="handle"
          onClick={() => {
            setTrashFolderWindow(1);
          }}
        >
          <Img src="icons/trash.png"></Img>
          <Label>Trash</Label>
        </IconWindows>
        {/* Sunrebels Info Page Icon */}
        <IconWindows
          className="handle"
          onClick={() => setRoadmap(1)}
        >
          <Img src="/icons/windows98-icons/png/tip.png"></Img>
          <Label>About Sunrebels</Label>
        </IconWindows>
        {/* Sorrisso Info Page Icon */}
        <IconWindows
          className="handle"
          onClick={() => {
            setAboutWindow(1);
          }}
        >
          <Img src="icons/about.png"></Img>
          <Label>About Sorriso</Label>
        </IconWindows>
        {/* XRP Cafe marketplace / mint link */}
        <IconWindows
          className="handle"
          onClick={() => window.open(xrpCafeUrl, "_blank")}
        >
          <Img src="icons/sunlogo-64px.png" />
          <Label>Mint Your Rebel</Label>
        </IconWindows>
        <IconWindows
          className="handle"
          onClick={() => {
            setBuilder(1);
          }}
        >
          <Img src="icons/windows98-icons/png/tools_gear-0.png"></Img>
          <Label>RebelBuilder.exe</Label>
        </IconWindows>
        <IconWindows
          className="handle"
          onClick={() => {
            setVideoFolderWindow(1);
          }}
        >
          <Img src="icons/folder.png"></Img>
          <Label>Video</Label>
        </IconWindows>
        {/* Tools Folder */}
        <IconWindows
          className="handle"
          onClick={() => {
            setToolsFolderWindow(1);
          }}
        >
          <Img src="icons/folder.png"></Img>
          <Label>Tools</Label>
        </IconWindows>
      </ContainerIconRight>

      <ContainerIcon isMobile={isMobile}>
        <IconWindows
          className="handle"
          onClick={() => window.open(twitterUrl, "_blank")}
        >
          <Img src="icons/twitter.png" />
          <Label>Twitter</Label>
        </IconWindows>
        <IconWindows
          className="handle"
          onClick={() => window.open(discordUrl, "_blank")}
        >
          <Img src="icons/discord.png" />
          <Label>Discord</Label>
        </IconWindows>
        {/* XRPL guide document */}
                <IconWindows
          className="handle"
          onClick={() => setFaqWindow(1)}
        >
          <Img src="icons/faq.png"></Img>
          <Label>XRPL_Guide.docx</Label>
        </IconWindows>
      </ContainerIcon>
      <>
        {!!roadMapWindows && (
          <Windows
            setClose={setRoadmap}
            title={"SunRebels"}
            margin={isMobile ? "" : "auto 5% auto auto"}
            isDisplay={isMobile ? true : isDisplayRoadmap}
            isPhone={isMobile}
          >
            <Roadmap />
          </Windows>
        )}
        {!!builder && (
          <Windows
            setClose={setBuilder}
            title={"RebelBuilder.exe"}
            margin={isMobile ? "" : "auto auto 60px 20px"}
            isDisplay={isMobile ? true : isDisplayBuilder}
            isPhone={isMobile}
          >
            <Builder />
          </Windows>
        )}
        {!!faqWindow && (
          <Windows
            setClose={setFaqWindow}
            title={"XRPL Guide.docx"}
            margin={isMobile ? "" : "30px auto auto 20px"}
            isDisplay={isMobile ? true : isDisplayFaq}
            isPhone={isMobile}
          >
            <Faq />
          </Windows>
        )}
        {!!aboutWindow && (
          <Windows
            setClose={setAboutWindow}
            title={"SORRISO"}
            margin={isMobile ? "" : "30px auto auto 20px"}
            isDisplay={isMobile ? true : isDisplayAbout}
            isPhone={isMobile}
          >
            <About />
          </Windows>
        )}
        {!!videoFolderWindow && (
          <Windows
            setClose={setVideoFolderWindow}
            title={"Video"}
            margin={isMobile ? "" : "30px auto auto 20px"}
            isDisplay={isMobile ? false : isDisplayVideoFolder}
            isPhone={isMobile}
          >
            <VideoFolder />
          </Windows>
        )}
        {!!videoPlayerWindow && (
          <Windows
            setClose={setVideoPlayerWindow}
            title={"Video player"}
            margin={isMobile ? "" : "auto auto auto auto"}
            isDisplay={isMobile ? true : isDisplayVideoPlayer}
            isPhone={isMobile}
          >
            <iframe
              width={isMobile ? "360px" : "600px"}
              height="360px"
              style={{ padding: "10px", maxWidth: "80vw" }}
              src="https://www.youtube.com/embed/kemivUKb4f4?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Windows>
        )}
        {!!trashFolderWindow && (
          <Windows
            setClose={setTrashFolderWindow}
            title={"Trash"}
            margin={isMobile ? "" : "30px auto auto 20px"}
            isDisplay={isMobile ? true : isDisplayTrashFolder}
            isPhone={isMobile}
          >
            <TrashFolder />
          </Windows>
        )}
        {!!toolsFolderWindow && (
          <Windows
            setClose={setToolsFolderWindow}
            title={"Tools"}
            margin={isMobile ? "" : "30px auto auto 20px"}
            isDisplay={isMobile ? true : isDisplayToolsFolder}
            isPhone={isMobile}
          >
            <ToolsFolder />
          </Windows>
        )}
        {!!vanityToolWindow && (
          <Windows
            setClose={setVanityTool}
            title={"XRP Vanity Address Generator"}
            margin={isMobile ? "" : "auto auto 60px 20px"}
            isDisplay={isMobile ? true : isDisplayVanityTool}
            isPhone={isMobile}
          >
            <VanityGenerator />
          </Windows>
        )}
      </>
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div<{ overFlow: Boolean }>`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  height: 80vh;
  z-index: 0;
  overflow: ${(props) => props.overFlow && "auto"};
  ${({ overFlow }) =>
    overFlow &&
    `
    margin-top: 20px;
    margin-bottom: 20vh;
    display: flex;
    flex-direction: column;
    height: fit-content;
  `}
  @media (max-width: 415px) {
    margin-top: 20px;
    margin-bottom: 20vh;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
`;

const ContainerIcon = styled.div<{ isMobile: Boolean }>`
  display: flex;
  position: fixed;
  right: 20px;
  flex-direction: column;
  ${({ isMobile }) =>
    isMobile &&
    `
    display: none
  `};
`;

const ContainerIconRight = styled(ContainerIcon)`
  position: fixed;
  left: 20px;
  flex-direction: column;
`;

const IconWindows = styled.div`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  width: 63px;
  height: 63px;
  margin: 0px 30px 30px 30px;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  color: white;
`;

const Img = styled.img`
  width: 80%;
  height: 80%;
`;

const Label = styled.div`
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
`;
