import { useState } from "react";
import styled from "styled-components";

export const Collapse = ({ datas }: any) => {
  return (
    <Container>
      <Content>
        <Box>
          {datas.map((item: { question: string; answer: string }) => {
            return (
              <CollapseStyled question={item.question} answer={item.answer} />
            );
          })}
        </Box>
      </Content>
    </Container>
  );
};

const CollapseStyled = ({
  question,
  answer,
}: {
  question: string;
  answer: any;
}) => {
  const [item, setItem] = useState(false);
  return (
    <Item onClick={() => setItem(!item)}>
      <Title dangerouslySetInnerHTML={{ __html: question }} />
      <Arrow showInfos={item} />
      <Infos showInfos={item}>
        <MiniSep />
        <div dangerouslySetInnerHTML={{ __html: answer }} />
      </Infos>
    </Item>
  );
};

const Arrow = styled.div<{ showInfos: boolean }>`
  transform: ${(a) => (a.showInfos ? "rotate(180deg)" : "rotate(0)")};
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  @media (max-width: 415px) {
    right: 5px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #9c9c9c;

  :last-child {
    border-bottom: none;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;

  @media (max-width: 415px) {
  }
`;

const MiniSep = styled.div`
  width: 60%;
  height: 1px;
  background-color: #9c9c9c;
  margin: 10px auto;
`;

const Infos = styled.div<{ showInfos: boolean }>`
  display: flex;
  flex-direction: column;
  line-height: 16px;
  font-size: 16px;
  display: flex;
  color: #555555;
  transition: all 0.4s ease-in-out;
  overflow: scroll;
  margin: auto 20px;
  max-height: ${(showInfos) => (showInfos.showInfos ? "245px" : "0px")};
`;

const Content = styled.div`
  display: flex;
  @media (max-width: 415px) {
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @media (max-width: 415px) {
    width: 100%;
    align-items: center;
  }
`;

const Container = styled.div`
  width: 800px;
  border: 1px solid black;
  margin: 5px;
  color: black;
  max-height: 85vh;
  max-width: 80vw;
  overflow: auto;
  @media (max-width: 415px) {
    width: 90vw;
    max-width: 90vw;
  }
  @media (max-device-width: 1415px) {
    @media (orientation: landscape) {
      width: 100vw;
      max-width: 90vw;
    }
  }
`;
