import Draggable from "react-draggable";
import styled from "styled-components";

interface IWindows {
  setClose: Function;
  title: String;
  width?: number;
  x?: number | string;
  y?: number | string;
  margin?: string;
  isPhone?: Boolean;
  isDisplay?: Boolean;
}

export const Windows: React.FC<IWindows> = (props) => {
  const isMobile = props.isPhone ? (props.isPhone as boolean) : false;

  return (
    <Draggable handle=".handle" disabled={isMobile}>
      <WindowsMedia
        margin={props.margin}
        isPhone={props.isPhone}
        isDisplay={props.isDisplay}
      >
        {" "}
        <BarWindows className="handle">
          <ContentTitle>
            <Title>{props.title}</Title>
          </ContentTitle>
          <Close
            onClick={() => props.setClose(false)}
            isActive={!props.isPhone}
          >
            <Img src="icons/close.png" />
          </Close>
        </BarWindows>
        {props.children}
      </WindowsMedia>
    </Draggable>
  );
};

const WindowsMedia = styled.div<{
  left?: Number;
  right?: Number;
  margin?: String;
  isPhone?: Boolean;
  isDisplay?: Boolean;
}>`
  overflow: hidden;
  display: ${(props) => (props.isDisplay ? "flex" : "none")};
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  background: #c2c6ca;
  font-size: 16px;
  -webkit-font-smoothing: none;
  width: fit-content;
  height: fit-content;
  box-shadow: 0.5px 0.5px 0 0.5px black, inset 1px 1px white,
    inset -1px -1px black, inset 2px 2px white;
  position: ${(props) => !props.isPhone && "absolute"};
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;

  bottom: 0;
  margin: ${({ margin }) => (!margin ? "auto" : margin + "")};
  @media (max-width: 415px) {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 10% auto auto auto;
  }
  @media (max-device-width: 1415px) {
    @media (orientation: landscape) {
      margin: auto auto 20px auto;
    }
  }
`;

const Img = styled.img``;

const BarWindows = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-weight: bold;
  width: 99%;
  height: 16px;
  color: white;
  background: #00a;
  padding: 3px 0;
  margin: 2px 3px 0 3px;
  align-items: center;
`;

const Close = styled.button<{ isActive: Boolean }>`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  text-decoration: none;
  border: 1px solid black;
  background-color: transparent;
  padding: 0;
  z-index: 10;
`;

const ContentTitle = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
`;

const Title = styled.div`
  padding: 0 10px;
`;
